:root {
  --primary-color: #FFAE00;
  --secondary-color: #04097f;
  --light-color: #f4f4f4;

}

/* Basics */
.top-15{
    margin-top: 15px !important;
}

.top-25{
    margin-top: 25px !important;
}

.top-50{
    margin-top: 50px !important;
}

.bottom-25{
    margin-bottom: 25px !important;
}

.right-5{
    margin-right: 5px !important;

}

.next{
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.color-primary{
  color: var(--primary-color) !important;
}

.color-secondary{
  color: var(--secondary-color) !important;
}

.wd-200{
  width: 200px !important;
}

.bold{
  font-weight: bold !important;
}

.bg-dark{
  background-color: var(--light-color) !important;
  padding-top : 5px;
  padding-bottom: 5px;
  margin-top:25px;
  margin-bottom: 25px;
  
}



.grid{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;

}
.container{
  display: flex !important;
  flex-direction: column;
}


/* NotFound */
.nietgevonden{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  width: 100vw;
}
.nietgevonden div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.btnnietgevonden{
  background-color: var(--primary-color);
  border-radius: 5px;
  color: white !important;
  padding: 1em 5em;
  margin-top:2vh;
  text-decoration: none;
  font-weight: 700;
}

.nietgevonden h1{
font-size: 5em;
color: var(--maincolor);
}

.nietgevonden h2{
  font-weight: 400;
  color: var(--opacity);
}

/* Images */
.img-header{
  width: 100%;
  object-fit: cover;
}

.img-content{
  width: 100%;
  object-fit: cover;
}

.sponsor-image{
  height: 110px !important;
}

/* Navbar */
.nav{
  padding : 2.5vh 2.5vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-title{
  text-decoration: none;
  color: black;
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  align-items: center;

}

.nav-logo{
  width: 50px;
}

.nav-links{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links a{
  text-decoration: none;
  color: var(--primary-color);
  margin-left: 20px;
  font-size: 1rem;
  transition:  0.5s;

}

.nav-links a:hover{
  color: var(--secondary-color);
}

.nav-links button{
  margin-left: 20px;
  padding : 8px 20px;
}

.nav-logo {
  display: none; /* Hide all logos by default */
}

.desktop-logo {
  display: block !important; /* Show desktop logo by default */
}

.mobile-logo {
  display: none !important; /* Hide mobile logo by default */
}
.nav-links.desktop {
  display: flex;
}

.nav-links.mobile {
  display: none;
}

/* Mobile */
@media (max-width: 768px) {
  .nav-links{
    display: none;
  }

  h2{
    font-size: 2.5em !important;
  }

  .nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary-color);
  }

  .nav-title{
    content: '';
    font-size: 0;
  }

  .teamContainer p{
    width: 100%;
  }  
  
  .nav-links.desktop {
    display: none;
}
.nav-links.mobile {
    display: flex;
}

  .next{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .next div{
    width: 100%;
  }

  h4{
    font-size: 2em !important;
  }

  .next div:first-child{
    margin-bottom: 20px;
  }

  .HeaderSubTitle{
    font-size: 2em !important;
  }

  
  .container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container div{
    height: max-content !important;
  }
  .container div ul li{
    margin-bottom: 50px !important;
    min-height: max-content !important;
  }

 li img{
    width: 100% !important;
    object-fit: contain  !important;
  }

  

  .container p{
    width: 90%;
  }
  .container h6{
    width: 90%;
  }
  .container h4{
    width: 90%;
  }

  .grid{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 50px;
  
  }
  .desktop-logo {
    display: none !important; /* Hide desktop logo on mobile screens */
}
.mobile-logo {
    display: block !important; /* Show mobile logo on mobile screens */
}
}


